<template>
  <div class="container">
    <headerMain></headerMain>
    <!-- <div class="random"></div> -->
    <div class="aboutus">
      <div class="main1">
        <div class="content">
          <div>{{ $t("langs.abMain1Name1") }}</div>
          <div>{{ $t("langs.abMain1Name2") }}</div>
          <div>
            {{ $t("langs.abMain1Name3") }}
          </div>
        </div>
      </div>
      <div class="main2">
        <div class="title">
          <!-- 覆盖领域  -->
          {{ $t("langs.abMain2Name1FuGai") }}
        </div>
        <div class="icon">
          <img src="../assets/img/main3_1.png" alt="" />
        </div>
        <div class="describe">
          {{ $t("langs.abMain2Name1Title") }}
        </div>
        <div class="picture">
          <div class="pictureMsg">
            <div>
              <img src="../assets/about/main2_1.png" alt="" />
            </div>
            <div>
              <!-- 数字货币交易 -->
              {{ $t("langs.abMain2Name1") }}
            </div>
          </div>
          <div class="pictureMsg">
            <div>
              <img src="../assets/about/main2_2.png" alt="" />
            </div>
            <div>
              <!-- 区块链教育 -->
              {{ $t("langs.abMain2Name2") }}
            </div>
          </div>

          <div class="pictureMsg">
            <div>
              <img src="../assets/about/main2_3.png" alt="" />
            </div>
            <div>
              <!-- 区块链项目孵化 -->
              {{ $t("langs.abMain2Name3") }}
            </div>
          </div>
          <div class="pictureMsg">
            <div>
              <img src="../assets/about/main2_4.png" alt="" />
            </div>
            <div>
              <!-- 区块链研究院 -->
              {{ $t("langs.abMain2Name4") }}
            </div>
          </div>
          <div class="pictureMsg">
            <div>
              <img src="../assets/about/main2_5.png" alt="" />
            </div>
            <div>
              <!-- 区块链公益慈善 -->
              {{ $t("langs.abMain2Name5") }}
            </div>
          </div>
        </div>
      </div>
      <div class="main3">
        <div class="title">
          <!-- 团队优势 -->
          {{ $t("langs.abMain3Name1") }}
        </div>
        <div class="icon">
          <img src="../assets/img/main3_1.png" alt="" />
        </div>
        <div class="box">
          <div class="box1">
            <div class="num">01</div>
            <div class="text">
              <img src="../assets/img/main5_3.png" alt="" />
              {{ $t("langs.abMain3Name2") }}
            </div>
            <div class="text1">
              <img src="../assets/img/main5_3.png" alt="" />
              {{ $t("langs.abMain3Name3") }}
            </div>
            <div class="text1">
              <img src="../assets/img/main5_3.png" alt="" />
              {{ $t("langs.abMain3Name4") }}
            </div>
            <div class="text1">
              <img src="../assets/img/main5_3.png" alt="" />
              {{ $t("langs.abMain3Name5") }}
            </div>
          </div>
          <div class="box1">
            <div class="num">02</div>
            <div class="text">
              <img src="../assets/img/main5_3.png" alt="" />
              {{ $t("langs.abMain3Name6") }}
            </div>
            <div class="text1">
              <img src="../assets/img/main5_3.png" alt="" />
              {{ $t("langs.abMain3Name7") }}
            </div>
            <div class="text1">
              <img src="../assets/img/main5_3.png" alt="" />
              {{ $t("langs.abMain3Name8") }}
            </div>
            <div class="text1">
              <img src="../assets/img/main5_3.png" alt="" />{{
                $t("langs.abMain3Name9")
              }}
            </div>
          </div>
          <div class="box1">
            <div class="num">03</div>
            <div class="text">
              <img src="../assets/img/main5_3.png" alt="" />
              {{ $t("langs.abMain3Name10") }}
            </div>
            <div class="text1">
              <img src="../assets/img/main5_3.png" alt="" />
              {{ $t("langs.abMain3Name11") }}
            </div>
            <div class="text1">
              <img src="../assets/img/main5_3.png" alt="" />
              {{ $t("langs.abMain3Name12") }}
            </div>
            <div class="text1">
              <img src="../assets/img/main5_3.png" alt="" />
              {{ $t("langs.abMain3Name13") }}
            </div>
          </div>
        </div>
      </div>
      <div class="main4">
        <div class="title">
          <!-- HEO生态 -->
          {{ $t("langs.abMain4Name1") }}
        </div>
        <div class="icon">
          <img src="../assets/img/main3_1.png" alt="" />
        </div>
        <div class="box">
          <div class="boxMsg">
            <div><img src="../assets/about/main4_1.png" alt="" /></div>
            <div>
              <!-- HEO发展基金 -->
              {{ $t("langs.abMain4Name2") }}
            </div>
          </div>
          <div class="boxMsg">
            <div><img src="../assets/about/main4_2.png" alt="" /></div>
            <div>
              <!-- 数字货币信托业务 -->
              {{ $t("langs.abMain4Name3") }}
            </div>
          </div>
          <div class="boxMsg">
            <div><img src="../assets/about/main4_3.png" alt="" /></div>
            <div>
              <!-- 数字货币结算系统 -->
              {{ $t("langs.abMain4Name4") }}
            </div>
          </div>
          <div class="boxMsg">
            <div><img src="../assets/about/main4_4.png" alt="" /></div>
            <div>
              <!-- 借贷系统 -->
              {{ $t("langs.abMain4Name5") }}
            </div>
          </div>
          <div class="boxMsg">
            <div><img src="../assets/about/main4_5.png" alt="" /></div>
            <div>
              <!-- HEO链上合约 -->
              {{ $t("langs.abMain4Name6") }}
            </div>
          </div>
          <div class="boxMsg">
            <div><img src="../assets/about/main4_6.png" alt="" /></div>
            <div>
              <!-- 游戏 -->
              {{ $t("langs.abMain4Name7") }}
            </div>
          </div>
          <div class="boxMsg">
            <div><img src="../assets/about/main4_7.png" alt="" /></div>
            <div>
              <!-- DEFI -->
              {{ $t("langs.abMain4Name8") }}
            </div>
          </div>
          <div class="boxMsg">
            <div><img src="../assets/about/main4_1.png" alt="" /></div>
            <div>
              <!-- 公益 -->
              {{ $t("langs.abMain4Name9") }}
            </div>
          </div>
        </div>
      </div>
      <div class="main5">
        <div class="title">
          <!-- HOE元素周期矿 -->
          {{ $t("langs.abMain6Name1s") }}
        </div>
        <div class="icon">
          <img src="../assets/img/main3_1.png" alt="" />
        </div>
        <div class="imgMsg">
          <img src="../assets/about/main5.png" alt="" />
        </div>
      </div>
      <div class="main6">
        <div class="title">
          <!-- 价值闭环路线 -->
          {{ $t("langs.abMain6Name1") }}
        </div>
        <div class="icon">
          <img src="../assets/img/main3_1.png" alt="" />
        </div>
        <div class="lineRoad">
          <div class="line">
            <img src="../assets/about/main6.png" alt="" />
          </div>
          <div class="road">
            <div class="box">
              <div class="box1">
                <!-- HOEX -->
                {{ $t("langs.abMain6Name2") }}
              </div>
              <div class="box2">
                <!-- 2020年10月24日10:24消耗了102.4gas费发布了HOE生态的第一枚价值10万USDT的纪念币HOEX -->
                {{ $t("langs.abMain6Name3") }}
              </div>
            </div>
            <div class="box">
              <div class="box1">
                <!-- HOE -->
                {{ $t("langs.abMain6Name4") }}
              </div>
              <div class="box2">
                <!-- HOEDefi去中心化金融聚合平台的生态通证，HOE可以通过挖矿获得。矿获得。当前价：60美金 -->
                {{ $t("langs.abMain6Name5") }}
              </div>
            </div>
            <div class="box">
              <div class="box1">Hoeswap</div>
              <div class="box2">
                <!-- 上线去中心化交易平台Hoeswap，交易手续费全部用于回购HOE -->
                {{ $t("langs.abMain6Name7") }}
              </div>
            </div>
            <div class="box">
              <div class="box1">HOEY</div>
              <div class="box2">
                <!-- HOEY是用于对HOE生态108矿的治理代币，持有HOE可以兑换108矿的初始额度私募资格 -->
                {{ $t("langs.abMain6Name9") }}
              </div>
            </div>
            <div class="box">
              <div class="box1">NFT</div>
              <div class="box2">
                <!-- HOE合约地址预留4000枚作为NFT分配，通过108矿产生的子矿币可以合成NFT，一枚NFT对应一件实物艺术品 -->
                {{ $t("langs.abMain6Name11") }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="main7">
        <div class="title">
          <!-- 合作伙伴 -->
          {{ $t("langs.main6Name1") }}
        </div>
        <div class="icon">
          <img src="../assets/img/main3_1.png" alt="" />
        </div>
        <div class="imgContent">
          <div><img src="../assets/img/main6_1.png" alt="" /></div>
          <div><img src="../assets/img/main6_2.png" alt="" /></div>
          <div><img src="../assets/img/main6_3.png" alt="" /></div>
          <div><img src="../assets/img/main6_4.png" alt="" /></div>
          <div><img src="../assets/img/main6_5.png" alt="" /></div>
          <div><img src="../assets/img/main6_6.png" alt="" /></div>
          <div><img src="../assets/img/main6_7.png" alt="" /></div>
          <div><img src="../assets/img/main6_8.png" alt="" /></div>
          <div><img src="../assets/img/main6_9.png" alt="" /></div>
          <div><img src="../assets/img/main6_10.png" alt="" /></div>
        </div>
      </div>
      <footerMain></footerMain>
    </div>
  </div>
</template>

<script>
import headerMain from "../components/Header.vue";
import footerMain from "../components/Footer.vue";
export default {
  components: {
    headerMain,
    footerMain
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {}
};
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  .random {
    height: 137px;
  }
  .aboutus {
    width: 100%;
    background-color: #000;
    .main1 {
      display: flex;
      justify-content: flex-end;
      color: #fff;
      padding: 290px 156px 250px 156px;
      background: url(../assets/about/main1.png);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      .content {
        width: 50%;
      }
      div:nth-child(1) {
        font-size: 73px;
      }
      div:nth-child(2) {
        font-size: 27px;
        margin: 60px 0 70px 0;
        color: #ffa900;
      }
      div:nth-child(3) {
        font-size: 18px;
      }
    }
    .main2 {
      width: 100%;
      min-height: 756px;
      background-color: #000;
      padding: 103px 248px 204px;
      .title {
        font-size: 30px;
        font-weight: 800;
        color: #ffffff;
        line-height: 42px;
        text-align: center;
      }
      .icon {
        width: 137px;
        margin: 0 auto;
        margin-top: 14px;
        margin-bottom: 48px;
        img {
          width: 137px;
          height: 6px;
          margin: 0 auto;
        }
      }
      .describe {
        width: 1141px;
        font-size: 20px;
        text-align: center;
        margin: 0 auto;
        // font-weight: 500;
        color: #ffffff;
      }
      .picture {
        display: flex;
        justify-content: space-around;
        margin-top: 70px;
        .pictureMsg {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          div:nth-child(1) {
            margin-bottom: 44px;
            img {
              width: 100px;
              height: 100px;
            }
          }
          div:nth-child(2) {
            font-size: 30px;
            // font-weight: 800;
            color: #ffffff;
            min-height: 50px;
          }
        }
      }
    }
    .main3 {
      width: 100%;
      // height: 819px;
      background-color: #171717;
      padding: 50px 150px 100px;
      .title {
        font-size: 30px;
        font-weight: 800;
        color: #ffffff;
        line-height: 42px;
        text-align: center;
      }
      .icon {
        width: 137px;
        margin: 0 auto;
        margin-top: 14px;
        margin-bottom: 48px;
        img {
          width: 137px;
          height: 6px;
          margin: 0 auto;
        }
      }
      .box {
        display: flex;
        justify-content: space-between;
        color: #fff;
        .box1 {
          width: 442px;
          height: 544px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          background-color: #202020;
          padding: 34px 38px;
          .num {
            width: 65px;
            height: 65px;
            line-height: 65px;
            border: 3px solid #ffa000;
            font-size: 27px;
            color: #ffa000;
            text-align: center;
          }
          img {
            width: 7px;
            height: 7px;
            // border-radius: 3px;
            // background-color: #ffa000;
            margin: 0px 10px 0 0;
          }
          .text {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-size: 27px;
            margin: 40px 0 60px 0;
          }
          .text1 {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-size: 20px;
            margin: 15px 0;
          }
        }
      }
    }
    .main4 {
      width: 100%;
      height: 730px;
      background-color: #000;
      padding: 100px 150px 10px;
      .title {
        font-size: 30px;
        font-weight: 800;
        color: #ffffff;
        line-height: 42px;
        text-align: center;
      }
      .icon {
        width: 137px;
        margin: 0 auto;
        margin-top: 14px;
        margin-bottom: 48px;
        img {
          width: 137px;
          height: 6px;
          margin: 0 auto;
        }
      }
      .box {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .boxMsg {
          width: 25%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          font-size: 30px;
          color: #fff;
          // font-weight: 500;
          // margin-bottom: 90px;
          img {
            width: 80px;
            height: 80px;
          }
          div:nth-child(2) {
            height: 150px;
          }
        }
      }
    }
    .main5 {
      width: 100%;
      height: 1090px;
      background-color: #171717;
      padding: 74px 0px 100px;
      .title {
        font-size: 30px;
        font-weight: 800;
        color: #ffffff;
        line-height: 42px;
        text-align: center;
      }
      .icon {
        width: 137px;
        margin: 0 auto;
        margin-top: 14px;
        margin-bottom: 48px;
        img {
          width: 137px;
          height: 6px;
          margin: 0 auto;
        }
      }
      .imgMsg {
        width: 1376px;
        margin: 0 auto;
        img {
          width: 1376px;
          height: 774px;
        }
      }
    }
    .main6 {
      width: 100%;
      min-height: 690px;
      background-color: #000;
      padding: 74px 0px 100px;
      .title {
        font-size: 30px;
        font-weight: 800;
        color: #ffffff;
        line-height: 42px;
        text-align: center;
      }
      .icon {
        width: 137px;
        margin: 0 auto;
        margin-top: 15px;
        margin-bottom: 91px;
        img {
          width: 137px;
          height: 6px;
          margin: 0 auto;
        }
      }
      .lineRoad {
        width: 1680px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        // background-color: pink;
        .line {
          display: block;
          img {
            width: 1357px;
            height: 21px;
          }
        }
        .road {
          width: 100%;
          display: flex;
          .box {
            width: 25%;
            display: flex;
            flex-direction: column;
            .box1 {
              color: #ffa900;
              font-size: 27px;
              font-weight: 800;
              margin: 50px 0 22px 0;
            }
            .box2 {
              width: 267px;
              font-size: 20px;
              // font-weight: 500;
              color: #ffffff;
              line-height: 50px;
            }
          }
        }
      }
    }
    .main7 {
      width: 100%;
      min-height: 727px;
      padding: 104px 260px 46px 260px;
      background: #171717;
      .title {
        font-size: 30px;
        font-weight: 800;
        color: #ffffff;
        line-height: 42px;
        text-align: center;
      }
      .icon {
        width: 137px;
        margin: 0 auto;
        margin-top: 14px;
        margin-bottom: 78px;
        img {
          width: 137px;
          height: 6px;
          margin: 0 auto;
        }
      }
      .imgContent {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        div {
          margin-bottom: 60px;
          img {
            width: 248px;
            height: 130px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .container {
    width: 100%;
    .random {
      height: 137px;
    }
    .aboutus {
      width: 100%;
      background-color: #000;
      .main1 {
        display: flex;
        justify-content: flex-end;
        color: #fff;
        padding: 25px 15px 25px 15px;
        background: url(../assets/about/main1.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        .content {
          width: 50%;
        }
        div:nth-child(1) {
          font-size: 30px;
        }
        div:nth-child(2) {
          font-size: 15px;
          margin: 10px 0 10px 0;
          color: #ffa900;
        }
        div:nth-child(3) {
          font-size: 9px;
        }
      }
      .main2 {
        width: 100%;
        min-height: 389px;
        background-color: #000;
        padding: 25px 15px 25px;
        .title {
          font-size: 15px;
          font-weight: 800;
          color: #ffffff;
          line-height: 21px;
          text-align: center;
        }
        .icon {
          width: 69px;
          margin: 0 auto;
          margin-top: 7px;
          margin-bottom: 24px;
          img {
            width: 69px;
            height: 3px;
            margin: 0 auto;
          }
        }
        .describe {
          width: 100%;
          font-size: 10px;
          text-align: center;
          margin: 0 auto;
          // font-weight: 500;
          color: #ffffff;
        }
        .picture {
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
          margin-top: 20px;
          .pictureMsg {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 48%;
            div:nth-child(1) {
              margin-bottom: 44px;
              img {
                width: 50px;
                height: 50px;
              }
            }
            div:nth-child(2) {
              font-size: 14px;
              font-weight: 800;
              color: #ffffff;
            }
          }
        }
      }
      .main3 {
        width: 100%;
        // height: 909px;
        background-color: #171717;
        padding: 25px 15px 25px;
        .title {
          font-size: 15px;
          font-weight: 800;
          color: #ffffff;
          line-height: 21px;
          text-align: center;
        }
        .icon {
          width: 69px;
          margin: 0 auto;
          margin-top: 7px;
          margin-bottom: 24px;
          img {
            width: 69px;
            height: 3px;
            margin: 0 auto;
          }
        }
        .box {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;
          color: #fff;
          .box1 {
            width: 220px;
            height: 250px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            background-color: #202020;
            padding: 10px 10px;
            margin-bottom: 10px;
            .num {
              width: 32px;
              height: 32px;
              line-height: 32px;
              border: 1px solid #ffa000;
              font-size: 14px;
              color: #ffa000;
              text-align: center;
            }
            span {
              width: 7px;
              height: 7px;
              border-radius: 3px;
              background-color: #ffa000;
              margin: 0px 10px 0 0;
            }
            .text {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              font-size: 14px;
              margin: 15px 0 5px 0;
            }
            .text1 {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              font-size: 10px;
              margin: 5px 0;
            }
          }
        }
      }
      .main4 {
        width: 100%;
        height: 380px;
        background-color: #000;
        padding: 25px 15px 25px;
        .title {
          font-size: 15px;
          font-weight: 800;
          color: #ffffff;
          line-height: 21px;
          text-align: center;
        }
        .icon {
          width: 69px;
          margin: 0 auto;
          margin-top: 7px;
          margin-bottom: 24px;
          img {
            width: 69px;
            height: 3px;
            margin: 0 auto;
          }
        }
        .box {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          .boxMsg {
            width: 25%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-size: 15px;
            color: #fff;
            // font-weight: 500;
            // margin-bottom: 15px;
            img {
              width: 40px;
              height: 40px;
            }
            div:nth-child(2) {
              height: 100px;
            }
          }
        }
      }
      .main5 {
        width: 100%;
        height: 350px;
        background-color: #171717;
        padding: 25px 15px 25px;
        .title {
          font-size: 15px;
          font-weight: 800;
          color: #ffffff;
          line-height: 21px;
          text-align: center;
        }
        .icon {
          width: 69px;
          margin: 0 auto;
          margin-top: 7px;
          margin-bottom: 24px;
          img {
            width: 69px;
            height: 3px;
            margin: 0 auto;
          }
        }
        .imgMsg {
          width: 330px;
          margin: 0 auto;
          img {
            width: 330px;
            height: 210px;
          }
        }
      }
      .main6 {
        width: 100%;
        min-height: 690px;
        background-color: #000;
        padding: 25px 15px 25px;
        .title {
          font-size: 15px;
          font-weight: 800;
          color: #ffffff;
          line-height: 21px;
          text-align: center;
        }
        .icon {
          width: 69px;
          margin: 0 auto;
          margin-top: 7px;
          margin-bottom: 24px;
          img {
            width: 69px;
            height: 3px;
            margin: 0 auto;
          }
        }
        .lineRoad {
          width: 100%;
          margin: 0 auto;
          display: flex;
          flex-direction: column;
          // background-color: pink;
          .line {
            display: none;
            img {
              width: 1357px;
              height: 21px;
            }
          }
          .road {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            text-align: center;
            .box {
              width: 50%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              .box1 {
                color: #ffa900;
                font-size: 14px;
                font-weight: 800;
                margin: 25px 0 11px 0;
              }
              .box2 {
                width: 130px;
                font-size: 10px;
                // font-weight: 500;
                color: #ffffff;
                line-height: 25px;
                text-align: center;
              }
            }
          }
        }
      }
      .main7 {
        width: 100%;
        min-height: 530px;
        padding: 25px 10px 25px 10px;
        background: #171717;
        .title {
          font-size: 15px;
          font-weight: 800;
          color: #ffffff;
          line-height: 21px;
          text-align: center;
        }
        .icon {
          width: 69px;
          margin: 0 auto;
          margin-top: 7px;
          margin-bottom: 24px;
          img {
            width: 69px;
            height: 3px;
            margin: 0 auto;
          }
        }
        .imgContent {
          display: flex;
          justify-content: space-around;
          flex-wrap: wrap;
          div {
            margin-bottom: 20px;
            img {
              width: 124px;
              height: 65px;
            }
          }
        }
      }
    }
  }
}
</style>
