<template>
  <div class="container">
    <headerMain></headerMain>
    <!-- <div class="random"></div> -->
    <div class="mainHead">
      <el-carousel :interval="3000" height="950px" class="carousel">
        <el-carousel-item v-for="item in list" :key="item.id">
          <img :src="item.img" style="height:100%;width:100%;" alt="图片1" />
        </el-carousel-item>
      </el-carousel>
      <el-carousel :interval="3000" height="260px" class="carouselIphone">
        <el-carousel-item v-for="item in list" :key="item.id">
          <img :src="item.img" style="height:100%;width:100%;" alt="图片2" />
        </el-carousel-item>
      </el-carousel>
      <div class="main2">
        <div class="main2content">
          <span>
            <!-- HOE基金会 -->
            {{ $t("langs.main2Name1") }}
          </span>
          {{ $t("langs.main2Name2") }}
        </div>
      </div>
      <div class="main3">
        <div class="title">
          <!-- HOE是基于Quorum架构搭建的符合金融级安全需求的商用平台 -->
          {{ $t("langs.main3Name1") }}
        </div>
        <div class="icon">
          <img src="../assets/img/main3_1.png" alt="" />
        </div>
        <div class="text1">
          {{ $t("langs.main3Name2") }}
        </div>
        <div class="text2">
          <div class="content">
            <div><img src="../assets/img/main3_2.png" alt="" /></div>
            <div>
              <!-- 安全 -->
              {{ $t("langs.main3Name3") }}
            </div>
            <div>{{ $t("langs.main3Name4") }}</div>
          </div>
          <div class="content">
            <div><img src="../assets/img/main3_3.png" alt="" /></div>
            <div>
              <!-- 公平 -->
              {{ $t("langs.main3Name5") }}
            </div>
            <div>{{ $t("langs.main3Name6") }}</div>
          </div>
          <div class="content">
            <div><img src="../assets/img/main3_4.png" alt="" /></div>
            <div>
              <!-- 高效 -->
              {{ $t("langs.main3Name7") }}
            </div>
            <div>{{ $t("langs.main3Name8") }}</div>
          </div>
        </div>
      </div>
      <div class="main4">
        <div class="title">
          <!-- HOE应用场景 -->
          {{ $t("langs.main4Name1") }}
        </div>
        <div class="icon">
          <img src="../assets/img/main3_1.png" alt="" />
        </div>
        <div class="guild">
          <div class="box">
            <div><img src="../assets/img/main4_1.png" alt="" /></div>
            <div>
              <!-- 获得DAO治理权限 -->
              {{ $t("langs.main4Name2") }}
            </div>
          </div>
          <div class="box">
            <div><img src="../assets/img/main4_2.png" alt="" /></div>
            <div>
              <!-- 存HOE可参与挖矿 -->
              {{ $t("langs.main4Name3") }}
            </div>
          </div>
          <div class="box">
            <div><img src="../assets/img/main4_3.png" alt="" /></div>
            <div>
              <!-- 基金盈利分红 -->
              {{ $t("langs.main4Name3s") }}
            </div>
          </div>
          <div class="box">
            <div><img src="../assets/img/main4_4.png" alt="" /></div>
            <div>
              <!-- 获得生态子币初始发行的私募资格 -->
              {{ $t("langs.main4Name4") }}
            </div>
          </div>
        </div>
      </div>
      <div class="main5">
        <div class="title">
          <!-- 社区覆盖全球 -->
          {{ $t("langs.main5Name1") }}
        </div>
        <div class="icon">
          <img src="../assets/img/main3_1.png" alt="" />
        </div>
        <div class="backImg">
          <img src="../assets/img/main5_1.png" alt="" />
        </div>
        <div class="guild">
          <div class="guildContent">
            <div><img src="../assets/img/main5_2.png" alt="" /></div>
            <div class="guildTitle">
              <!-- 去中心化网络 -->
              {{ $t("langs.main5Name1") }}
            </div>
          </div>
          <div class="guildContent">
            <div><img src="../assets/img/main5_3.png" alt="" /></div>
            <div class="guildTitle">
              <!-- 主要用户群 -->
              {{ $t("langs.main5Name3") }}
            </div>
          </div>
          <div class="guildContent">
            <div><img src="../assets/img/main5_4.png" alt="" /></div>
            <div class="guildTitle">
              <!-- HOE全球战略分布 -->
              {{ $t("langs.main5Name4") }}
            </div>
          </div>
        </div>
        <div class="leaveMessage">
          <div class="title1">
            <!-- 留言 -->
            {{ $t("langs.main5Name5") }}
          </div>
          <div class="title2">
            <!-- 写下您的留言，我们会尽快回复您 -->
            {{ $t("langs.main5Name6") }}
          </div>
          <div class="message">
            <input v-model="message" :placeholder="$t('langs.placeholder1')" />
          </div>
          <div class="email">
            <input v-model="email" :placeholder="$t('langs.placeholder2')" />
          </div>
          <div class="submitBtn cursor">
            <!-- 提交 -->
            {{ $t("langs.main5Name7") }}
          </div>
        </div>
      </div>
      <div class="main6">
        <div class="title">
          <!-- 合作伙伴 -->
          {{ $t("langs.main6Name1") }}
        </div>
        <div class="icon">
          <img src="../assets/img/main3_1.png" alt="" />
        </div>
        <div class="imgContent">
          <div><img src="../assets/img/main6_1.png" alt="" /></div>
          <div><img src="../assets/img/main6_2.png" alt="" /></div>
          <div><img src="../assets/img/main6_3.png" alt="" /></div>
          <div><img src="../assets/img/main6_4.png" alt="" /></div>
          <div><img src="../assets/img/main6_5.png" alt="" /></div>
          <div><img src="../assets/img/main6_6.png" alt="" /></div>
          <div><img src="../assets/img/main6_7.png" alt="" /></div>
          <div><img src="../assets/img/main6_8.png" alt="" /></div>
          <div><img src="../assets/img/main6_9.png" alt="" /></div>
          <div><img src="../assets/img/main6_10.png" alt="" /></div>
        </div>
      </div>
      <FooterMain></FooterMain>
    </div>
  </div>
</template>

<script>
import headerMain from "../components/Header.vue";
import FooterMain from "../components/Footer.vue";
export default {
  components: {
    headerMain,
    FooterMain
  },
  data() {
    return {
      list: [
        {
          id: "1",
          img: require("../assets/img/lunbo1.png")
        },
        {
          id: "2",
          img: require("../assets/img/lunbo2.png")
        }
      ],
      message: "",
      email: "",
      top: 0,
      width: 0
    };
  },
  created() {},
  mounted() {
    window.addEventListener("scroll", this.scrollToTop);
    window.addEventListener("scroll", this.scrollToTop);
  },
  methods: {
    scrollToTop() {
      this.top = document.documentElement.scrollTop;
      // this.width = document.documentElement.clientWidth;
      console.log("this.top", this.top);
      // console.log("this.width", this.width);
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  .random {
    height: 137px;
  }
  .mainHead {
    width: 100%;
    .carouselIphone {
      display: none;
    }
    .carousel {
      display: block;
    }
    .el-carousel__container {
      height: 100% !important;
    }
    .main2 {
      width: 100%;
      min-height: 756px;
      padding: 0 150px;
      background-image: url(../assets/img/main2.png);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .main2content {
        width: 630px;
        line-height: 55px;
        font-size: 16px;
        color: #fff;
        // font-weight: 500;
        span {
          font-weight: 800;
          color: #ffa900;
        }
      }
    }
    .main3 {
      width: 100%;
      min-height: 756px;
      padding: 104px 150px 90px 150px;
      background-color: #000;
      .title {
        font-size: 30px;
        font-weight: 800;
        color: #ffffff;
        line-height: 42px;
        text-align: center;
      }
      .icon {
        width: 137px;
        margin: 0 auto;
        margin-top: 14px;
        margin-bottom: 48px;
        img {
          width: 137px;
          height: 6px;
          margin: 0 auto;
        }
      }
      .text1 {
        width: 1118px;
        font-size: 18px;
        // font-weight: 500;
        color: #ffffff;
        line-height: 37px;
        text-align: center;
        margin: 0 auto;
      }
      .text2 {
        color: #ffffff;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: 65px;
        .content {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-around;
          img {
            width: 95px;
            height: 95px;
          }
          div:nth-child(2) {
            font-size: 26px;
            margin: 82px 0 50px 0;
          }
          div:nth-child(3) {
            font-size: 18px;
          }
        }
      }
    }
    .main4 {
      width: 100%;
      min-height: 489px;
      padding: 104px 150px 90px 150px;
      background: #171717;
      .title {
        font-size: 30px;
        font-weight: 800;
        color: #ffffff;
        line-height: 42px;
        text-align: center;
      }
      .icon {
        width: 137px;
        margin: 0 auto;
        margin-top: 14px;
        margin-bottom: 48px;
        img {
          width: 137px;
          height: 6px;
          margin: 0 auto;
        }
      }
      .guild {
        display: flex;
        justify-content: space-around;
        .box {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          img {
            width: 62px;
            height: 62px;
          }
          div:nth-child(2) {
            width: 300px;
            color: #ffffff;
            font-size: 20px;
            font-weight: 500;
            line-height: 28px;
            text-align: center;
            margin-top: 70px;
            height: 70px;
          }
        }
      }
    }
    .main5 {
      width: 100%;
      min-height: 1280px;
      padding: 104px 150px 46px 150px;
      background: #000000;
      .title {
        font-size: 30px;
        font-weight: 800;
        color: #ffffff;
        line-height: 42px;
        text-align: center;
      }
      .icon {
        width: 137px;
        margin: 0 auto;
        margin-top: 14px;
        margin-bottom: 78px;
        img {
          width: 137px;
          height: 6px;
          margin: 0 auto;
        }
      }
      .backImg {
        width: 896px;
        margin: 0 auto;
        img {
          width: 896px;
          height: 456px;
        }
      }
      .guild {
        width: 744px;
        margin: 0 auto;
        display: flex;
        justify-content: space-around;
        margin-top: 57px;
        margin-bottom: 105px;
        // margin: 57px 0 105px 0;
        .guildContent {
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 26px;
            height: 26px;
          }
          .guildTitle {
            font-size: 20px;
            // font-weight: 800;
            color: #ffffff;
            line-height: 28px;
            margin-left: 15px;
          }
        }
      }
      .leaveMessage {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #fff;
        .title1 {
          font-size: 30px;
        }
        .title2 {
          font-size: 18px;
          margin: 6px 0 12px 0;
        }
        input {
          width: 925px;
          height: 68px;
          background: #252121;
          border-radius: 34px;
          border: none;
          outline: none;
          padding: 20px;
        }
        .message {
          font-size: 20px;
        }
        .email {
          font-size: 20px;
          margin: 26px 0;
        }
        .submitBtn {
          width: 181px;
          height: 55px;
          line-height: 55px;
          background: #ffa900;
          border-radius: 28px;
          font-size: 26px;
          font-weight: 800;
          text-align: center;
        }
      }
    }
    .main6 {
      width: 100%;
      min-height: 727px;
      padding: 104px 260px 46px 260px;
      background: #171717;
      .title {
        font-size: 30px;
        font-weight: 800;
        color: #ffffff;
        line-height: 42px;
        text-align: center;
      }
      .icon {
        width: 137px;
        margin: 0 auto;
        margin-top: 14px;
        margin-bottom: 78px;
        img {
          width: 137px;
          height: 6px;
          margin: 0 auto;
        }
      }
      .imgContent {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        div {
          margin-bottom: 60px;
          img {
            width: 248px;
            height: 130px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .container {
    width: 100%;
    .random {
      height: 0px;
    }
    .mainHead {
      width: 100%;
      .carousel {
        display: none;
      }
      .carouselIphone {
        display: block;
      }
      .el-carousel__container {
        height: 100% !important;
      }
      .main2 {
        width: 100%;
        min-height: 300px;
        padding: 20px 20px;
        background-image: url(../assets/img/main2.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .main2content {
          flex: 1;
          width: 315px;
          line-height: 25px;
          font-size: 8px;
          color: #fff;
          // font-weight: 500;
          span {
            font-weight: 800;
            color: #ffa900;
          }
        }
      }
      .main3 {
        width: 100%;
        min-height: 378px;
        padding: 25px 8px 23px 8px;
        background-color: #000;
        .title {
          font-size: 15px;
          font-weight: 800;
          color: #ffffff;
          line-height: 21px;
          text-align: center;
        }
        .icon {
          width: 69px;
          margin: 0 auto;
          margin-top: 7px;
          margin-bottom: 24px;
          img {
            width: 69px;
            height: 3px;
            margin: 0 auto;
          }
        }
        .text1 {
          width: 335px;
          font-size: 9px;
          // font-weight: 500;
          color: #ffffff;
          line-height: 19px;
          text-align: center;
          margin: 0 auto;
        }
        .text2 {
          color: #ffffff;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          margin-top: 14px;
          .content {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
            img {
              width: 40px;
              height: 40px;
            }
            div:nth-child(2) {
              font-size: 13px;
              margin: 15px 0 10px 0;
            }
            div:nth-child(3) {
              font-size: 9px;
              height: 36px;
            }
          }
        }
      }
      .main4 {
        width: 100%;
        min-height: 350px;
        padding: 25px 10px 25px 10px;
        background: #171717;
        .title {
          font-size: 15px;
          font-weight: 800;
          color: #ffffff;
          line-height: 21px;
          text-align: center;
        }
        .icon {
          width: 69px;
          margin: 0 auto;
          margin-top: 7px;
          margin-bottom: 24px;
          img {
            width: 69px;
            height: 3px;
            margin: 0 auto;
          }
        }
        .guild {
          display: flex;
          justify-content: space-around;
          flex-wrap: wrap;
          .box {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 10px;
            img {
              width: 30px;
              height: 30px;
            }
            div:nth-child(2) {
              width: 150px;
              color: #ffffff;
              font-weight: 500;
              line-height: 20px;
              font-size: 10px;
              text-align: center;
              margin-top: 20px;
              height: 40px;
            }
          }
        }
      }
      .main5 {
        width: 100%;
        min-height: 600px;
        padding: 25px 10px 25px 10px;
        background: #000000;
        .title {
          font-size: 15px;
          font-weight: 800;
          color: #ffffff;
          line-height: 21px;
          text-align: center;
        }
        .icon {
          width: 69px;
          margin: 0 auto;
          margin-top: 7px;
          margin-bottom: 24px;
          img {
            width: 69px;
            height: 3px;
            margin: 0 auto;
          }
        }
        .backImg {
          width: 300px;
          margin: 0 auto;
          img {
            width: 300px;
            height: 200px;
          }
        }
        .guild {
          width: 100%;
          margin: 0 auto;
          display: flex;
          justify-content: space-around;
          margin-top: 25px;
          margin-bottom: 50px;
          .guildContent {
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              width: 13px;
              height: 13px;
            }
            .guildTitle {
              font-size: 10px;
              // font-weight: 500;
              color: #ffffff;
              line-height: 14px;
              margin-left: 5px;
            }
          }
        }
        .leaveMessage {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          color: #fff;
          .title1 {
            font-size: 15px;
          }
          .title2 {
            font-size: 9px;
            margin: 6px 0 12px 0;
          }
          input {
            width: 300px;
            height: 30px;
            background: #252121;
            border-radius: 15px;
            border: none;
            outline: none;
            padding: 10px;
          }
          .message {
            font-size: 10px;
          }
          .email {
            font-size: 10px;
            margin: 13px 0;
          }
          .submitBtn {
            width: 90px;
            height: 25px;
            line-height: 25px;
            background: #ffa900;
            border-radius: 14px;
            font-size: 13px;
            font-weight: 800;
            text-align: center;
          }
        }
      }
      .main6 {
        width: 100%;
        min-height: 530px;
        padding: 25px 10px 25px 10px;
        background: #171717;
        .title {
          font-size: 15px;
          font-weight: 800;
          color: #ffffff;
          line-height: 21px;
          text-align: center;
        }
        .icon {
          width: 69px;
          margin: 0 auto;
          margin-top: 7px;
          margin-bottom: 24px;
          img {
            width: 69px;
            height: 3px;
            margin: 0 auto;
          }
        }
        .imgContent {
          display: flex;
          justify-content: space-around;
          flex-wrap: wrap;
          div {
            margin-bottom: 20px;
            img {
              width: 124px;
              height: 65px;
            }
          }
        }
      }
    }
  }
}
</style>
