import Vue from 'vue'
import Vuex from 'vuex'
//异步请求
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    //所有的任务列表
    list: [],
    // 文本框的内容
    inputValue: '郭敏辉',
    id: 5,
    viewKey: 'all'
  },
  // 同步请求 唯一可以修改state里面的数值
  // 更改 Vuex 的 store 中的状态的唯一方法是提交 mutation
  // 所以 mutations 上存放的一般就是我们要改变 state 的一些方法
  mutations: {
    // 定义一个方法来修改list数据
    // state是固定参数，谁调用这个方法都要传一个list进来
    initList(state, list) {
      state.list = list
    },
    setInputValue(state, val) {
      state.inputValue = val
      // console.log('111', state.inputValue);
    },
    addItems(state) {
      const obj = {
        id: state.id,
        info: state.inputValue.trim(),
        done: false
      }
      state.list.push(obj)
      state.id++
      state.inputValue = ''
    },
    removeItem(state, id) {
      const i = state.list.findIndex((item) => item.id == id)
      if (i !== -1) {
        state.list.splice(i, 1)
      }
    },
    changeState(state, param) {
      const i = state.list.findIndex((item) => item.id == param.id)
      if (i !== -1) {
        state.list[i].done = param.status
      }
    },
    clearItems(state) {
      const newList = state.list.filter(item => item.done == false)
      state.list = newList
    },
    changeViewKey(state, key) {
      state.viewKey = key
    }
  },
  // 发异步请求
  actions: {
    getList(context) {
      axios.get('./list.json').then(({
        data
      }) => {
        console.log(data);
        context.commit('initList', data)
      })
    }
  },

  // getters上简单来说就是存放一些公共函数供组件调用
  getters: {
    unDoneLength(state) {
      return state.list.filter(x => x.done === false).length
    },
    infoList(state) {
      if (state.viewKey === 'all') {
        return state.list
      }
      if (state.viewKey === 'undone') {
        return state.list.filter(x => !x.done)
      }
      if (state.viewKey === 'done') {
        return state.list.filter(x => x.done == true)
      }
      return state.list
    }
  },
  modules: {}
})
