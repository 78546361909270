import Vue from 'vue'
import VueRouter from 'vue-router'
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'
import MainHead from '../views/MainHead.vue'
import MainAboutUs from '../views/MainAboutUs.vue'
import MainContactUs from '../views/MainContactUs.vue'
import WhiteBook from '../views/WhiteBook.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    redirect: '/homepage'
  },
  {
    path: '/homepage',
    name: 'homepage',
    component: MainHead,
    id: 1
  },
  {
    path: '/aboutus',
    name: 'aboutus',
    component: MainAboutUs,
    id: 2
  },
  {
    path: '/contactus',
    name: 'contactus',
    component: MainContactUs,
    id: 3
  },
  {
    path: '/whitebook',
    name: 'whitebook',
    component: WhiteBook,
    id: 4
  },
  {
    path: '/footer',
    name: 'footer',
    component: Footer,
    id: 5
  },
  {
    path: '/header',
    name: 'header',
    component: Header,
    id: 6
  },
]

const router = new VueRouter({
  routes
})


export default router
