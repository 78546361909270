// 中文

export const langs = {
  headName1: '首页',
  headName2: '关于我们',
  headName3: '白皮书',
  headName4: '联系我们',
  placeholder1: '留言',
  placeholder2: '邮箱',
  // 首页
  main2Name1: 'HOE基金会',
  main2Name2: '背靠全球规模最大的资产管理集团，在传统金融领域和金融科技创新领域深耕多年，拥有丰富的行业资源和金融产品运作经验。HOE致力于打造下一代Defi开放金融生态，平台主旨是创建一个开放、透明和安全的去中心化金融环境，提供生态孵化、商业模式咨询和竞争优势赋能等生态支撑策略，让开发者能够创建更多更好的去中心化服务。',
  main3Name1: 'HOE是基于Quorum架构搭建的符合金融级安全需求的商用平台',
  main3Name2: 'HOE Finance基于摩根大通（J.P Morgan) Quorum各异的场景应用的前提下，取得安全性与性能的均衡点。并在此基础上，提供完全友好的用户入口，使更多中小用户能够以尽可能低的门槛使用区块链应用服务。',
  main3Name3: '安全',
  main3Name4: '世界顶级安防系统',
  main3Name5: '公平',
  main3Name6: '数据公开，永久存档，公开透明',
  main3Name7: '高效',
  main3Name8: '顶级服务器群，为您的交易保驾护航',
  main4Name1: 'HOE应用场景',
  main4Name2: '获得DAO治理权限',
  main4Name3: '存HOE可参与挖矿',
  main4Name3s: '基金盈利分红',
  main4Name4: '获得生态子币初始发行的私募资格',
  main5Name1: '社区覆盖全球',
  main5Name2: '去中心化网络',
  main5Name3: '主要用户群',
  main5Name4: 'HOE全球战略分布',
  main5Name5: '留言',
  main5Name6: '写下您的留言，我们会尽快回复您',
  main5Name7: '提交',
  main6Name1: '合作伙伴',
  // 关于我们
  abMain1Name1: '关于我们',
  abMain1Name2: 'HOE是基于Quorum架构搭建的符合金融级安全需求的商用平台',
  abMain1Name3: 'HOE Finance基于摩根大通（J.P Morgan) Quorum 底层搭建的符合金融级安全需求的商用平台，是全球性普惠的区块链生态系统。该架构的优势在于满足大量需求各异的场景应用的前提下，取得安全性与性能的均衡点。并在此基础上，提供完全友好的用户入口，使更多中小用户能够以尽可能低 的门槛使用区块链应用服务。',
  abMain2Name1FuGai: '覆盖领域',
  abMain2Name1Title: 'HOE，致力于打造下一代Defi开放金融生态，向全球提供广泛的数字货币交易、区块链教育、区块链项目孵化、区块链资产发行平台、区块链研究院以及区块链公益慈善等服务',
  abMain2Name1: '数字货币交易',
  abMain2Name2: '区块链教育',
  abMain2Name3: '区块链项目孵化',
  abMain2Name4: '区块链研究院',
  abMain2Name5: '区块链公益慈善',
  abMain3Name1: '团队优势',
  abMain3Name2: '世界顶尖安全风控体系',
  abMain3Name3: '安全可信赖的价值网络服务',
  abMain3Name4: '3年数字资产交易服务经验',
  abMain3Name5: ' 专业分布式架构和防 DDOS 攻击系统',
  abMain3Name6: '全球本土化专业运营团队',
  abMain3Name7: '全球生态布局',
  abMain3Name8: '多个国家设立本土化交易服务中心',
  abMain3Name9: '打造多业务形态为一体的区块链生态圈',
  abMain3Name10: '基金实力雄厚',
  abMain3Name11: '背靠贝莱德投资管理集团，',
  abMain3Name12: '拥有全球顶尖的基金管理实力',
  abMain3Name13: '设立用户保护基金',
  abMain4Name1: 'HEO生态',
  abMain4Name2: 'HEO发展基金',
  abMain4Name3: '数字货币信托业务',
  abMain4Name4: '数字货币结算系统',
  abMain4Name5: '借贷系统',
  abMain4Name6: 'HEO链上合约',
  abMain4Name7: '游戏',
  abMain4Name8: 'DEFI',
  abMain4Name9: '公益',
  abMain5Name1: 'HEO生态',
  abMain6Name1: '价值闭环路线',
  abMain6Name1s: "HOE元素周期矿",
  abMain6Name2: 'HOEX',
  abMain6Name3: '2020年10月24日10:24消耗了102.4gas费发布了HOE生态的第一枚价值10万USDT的纪念币HOEX',
  abMain6Name4: 'HOE',
  abMain6Name5: ' HOEDefi去中心化金融聚合平台的生态通证，HOE可以通过挖矿获得。矿获得。当前价：60美金',
  abMain6Name6: 'Hoeswap',
  abMain6Name7: '上线去中心化交易平台Hoeswap，交易手续费全部用于回购HOE',
  abMain6Name8: 'HOEY',
  abMain6Name9: ' HOEY是用于对HOE生态108矿的治理代币，持有HOE可以兑换108矿的初始额度私募资格',
  abMain6Name10: 'NFT',
  abMain6Name11: 'HOE合约地址预留4000枚作为NFT分配，通过108矿产生的子矿币可以合成NFT，一枚NFT对应一件实物艺术品',
  //联系我们
  conMain1Name1: '联系电话',
  conMain1Name2: '工作日',
  conMain1Name3: '节假日',
  conMain1Name4: '地址：武汉市洪山区光谷大道235号',
  conMain1Name5: '联系我们',
  conMain1Name6: '查看地图',
  conMain2Name1: '客服电话',
  conMain2Name2: '商务合作',
  conMain2Name3: '加入我们',
  conMain2Name4: '资讯',

}
