<template>
  <div>
    <div class="pdf" v-show="fileType === 'pdf'">
      <p class="arrow">
        <span
          v-if="this.$i18n.locale == 'en'"
          @click="changePdfPage(0)"
          class="turn"
          :class="{ grey: currentPage == 1 }"
          >Preview</span
        >

        <span
          v-else
          @click="changePdfPage(0)"
          class="turn"
          :class="{ grey: currentPage == 1 }"
          >上一页</span
        >

        {{ currentPage }} / {{ pageCount }}

        <span
          v-if="this.$i18n.locale == 'en'"
          @click="changePdfPage(1)"
          class="turn"
          :class="{ grey: currentPage == pageCount }"
          >Next</span
        >

        <span
          v-else
          @click="changePdfPage(1)"
          class="turn"
          :class="{ grey: currentPage == pageCount }"
          >下一页</span
        >
      </p>

      <pdf
        :src="$route.query.bookPath"
        :page="currentPage"
        @loaded="loadPdfHandler"
        @page-loaded="currentPage = $event"
        @num-pages="pageCount = $event"
      ></pdf>
    </div>
  </div>
</template>

<script>
import pdf from "vue-pdf";
export default {
  components: {
    pdf
  },
  data() {
    return {
      currentPage: 0, // pdf文件页码
      pageCount: 0, // pdf文件总页数
      fileType: "pdf" // 文件类型
    };
  },
  created() {},
  mounted() {},
  methods: {
    changePdfPage(val) {
      if (val === 0 && this.currentPage > 1) {
        this.currentPage--;
      }
      if (val === 1 && this.currentPage < this.pageCount) {
        this.currentPage++;
      }
    },
    loadPdfHandler(e) {
      this.currentPage = 1; // 加载的时候先加载第一页
    }
  }
};
</script>

<style></style>
