<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {}
};
</script>

<style lang="less">
// #app {
// }
body,
html {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}
li {
  list-style: none;
}
#nav {
  padding: 30px;
  a {
    font-weight: bold;
    // color: #2c3e50;
    // &.router-link-exact-active {
      // color: #42b983;
    // }
  }
}
.cursor {
  cursor: pointer;
}
</style>
