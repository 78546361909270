// 英文

export const langs = {
  headName1: 'Home',
  headName2: 'About',
  headName3: 'White',
  headName4: 'Contact',
  placeholder1: 'Leave a message',
  placeholder2: 'email',
  // 首页
  main2Name1: 'HOE foundation',
  main2Name2: "With the backing of the world's largest asset management group, it has been deeply engaged in the traditional financial field and the financial technology innovation field for many years, and has a wealth of industry resources and financial product operation experience. HOE is committed to creating the next generation of DEFI open financial ecosystem. The platform aims to create an open, transparent and safe decentralized financial environment, providing ecological support strategies such as ecological incubation, business model consulting and competitive advantage empowerment, so that developers can create more and better decentralized services.",
  main3Name1: 'HOE is a commercial platform based on the Quorum architecture that meets financial grade security requirements',
  main3Name2: 'HOE Finance achieves a balance between security and performance based on the various scenarios used by J.P. Morgan Quorum. And on this basis, to provide a completely friendly user entry, so that more small and medium-sized users can use the blockchain application services with the lowest possible threshold.',
  main3Name3: 'security',
  main3Name4: 'security system',
  main3Name5: 'fairness',
  main3Name6: 'transparent,open, archive',
  main3Name7: 'efficient',
  main3Name8: 'Top server, escort transaction',
  main4Name1: 'HOE application scenarios',
  main4Name2: 'Obtain DAO governance rights',
  main4Name3: 'HOE can be involved in mining',
  main4Name3s: 'Share out bonus',
  main4Name4: 'Obtain the private placement qualification for the initial issuance of ecological sub-coins',
  main5Name1: 'Global community coverage',
  main5Name2: 'Decentralized networks',
  main5Name3: 'Major User Groups',
  main5Name4: "HOE's global strategic distribution",
  main5Name5: 'Leave a message',
  main5Name6: "Leave a message and we'll get back to you as soon as possible",
  main5Name7: 'submit',
  main6Name1: 'partners',
  // 关于我们
  abMain1Name1: 'About us',
  abMain1Name2: 'HOE is a commercial platform based on the Quorum architecture that meets financial grade security requirements',
  abMain1Name3: 'HOE Finance is a commercial platform based on the underlying structure of J.P. Morgan Quorum that meets the needs of financial-grade security. It is a global blockchain ecosystem for all benefits. The advantage of this architecture is to achieve a balance point between security and performance under the premise of satisfying a large number of scenarios with different requirements. And on this basis, to provide a completely friendly user entry, so that more small and medium-sized users can use the blockchain application services with the lowest possible threshold.',
  abMain2Name1FuGai: 'Coverage area',
  abMain2Name1Title: 'HOE, committed to creating the next generation of DEFI open financial ecosystem, provides a wide range of digital currency transactions, blockchain education, blockchain project incubation, blockchain asset issuance platform, blockchain research institute, blockchain charity and other services to the world',
  abMain2Name1: 'Currency Trading',
  abMain2Name2: 'Blockchain Education',
  abMain2Name3: 'Project incubation',
  abMain2Name4: 'Research Institute',
  abMain2Name5: 'Philanthropy',
  abMain3Name1: 'Team advantage',
  abMain3Name2: "The world's top safety risk control system",
  abMain3Name3: 'Secure and trusted value web services',
  abMain3Name4: '3 years experience in digital asset trading services',
  abMain3Name5: 'Professional distributed architecture and anti - DDoS attack system',
  abMain3Name6: 'Glocalization professional operation team',
  abMain3Name7: 'Global Ecological Layout',
  abMain3Name8: 'Many countries set up local trading service centers',
  abMain3Name9: 'Create a blockchain ecosystem integrating multiple business forms',
  abMain3Name10: 'Strong Fund Strength',
  abMain3Name11: 'It is backed by BlackRock Investment Management',
  abMain3Name12: "Has the world's top fund management strength",
  abMain3Name13: 'Establish a user protection fund',
  abMain4Name1: 'HEO ecological',
  abMain4Name2: 'HEO development fund',
  abMain4Name3: 'Trust business',
  abMain4Name4: 'Settlement system',
  abMain4Name5: 'Lending system',
  abMain4Name6: 'HEO on-chain contracts',
  abMain4Name7: 'Game',
  abMain4Name8: 'DEFI',
  abMain4Name9: 'Public welfare',
  abMain5Name1: 'HEO ecological',
  abMain6Name1: 'Value closed loop path',
  abMain6Name1s: 'HOE element periodic ore',
  abMain6Name2: 'HOEX',
  abMain6Name3: "HOE Eco's first USD100,000 commemorative coin, HOEX, was released on October 24, 2020 at 10:24, at a cost of 102.4gas",
  abMain6Name4: 'HOE',
  abMain6Name5: "An ecological token for HOEDefi's decentralized financial aggregation platform, HOE can be obtained through mining. The ore. Current price: $60",
  abMain6Name6: 'Hoeswap',
  abMain6Name7: 'Launch the decentralized trading platform Hoeswap, and all transaction fees will be used to buy back',
  abMain6Name8: 'HOEY',
  abMain6Name9: 'HOEY is a token used for the treatment of HOE Eco 108 Mine. Holder of HOE can exchange the initial quota of 108 Mine for private placement',
  abMain6Name10: 'NFT',
  abMain6Name11: 'The HOE contract address is reserved for 4000 NFTs to be allocated. NFTs can be synthesized from the sub-coins generated from 108 mines, one NFT for each physical artwork',
  //联系我们
  conMain1Name1: 'Contact phone',
  conMain1Name2: 'Working days',
  conMain1Name3: 'The holiday season',
  conMain1Name4: 'Address: 235 Guanggu Avenue, Hongshan District, Wuhan City',
  conMain1Name5: 'Contact us',
  conMain1Name6: 'Map',
  conMain2Name1: 'Customer service telephone',
  conMain2Name2: 'Business cooperation',
  conMain2Name3: 'Join us',
  conMain2Name4: 'information',

}
