<template>
  <div class="container">
    <div class="content">
      <div class="header">
        <div class="left">
          <img src="../assets/img/herder1.png" alt="" />
        </div>
        <div class="right">
          <div
            class="headerText cursor"
            :class="{ active: $route.path.indexOf('homepage') > 0 }"
            @click="handleHeader1()"
          >
            <!-- 首页 -->
            {{ $t("langs.headName1") }}
          </div>
          <div
            class="headerText cursor"
            :class="{ active: $route.path.indexOf('aboutus') > 0 }"
            @click="handleHeader2()"
          >
            <!-- 关于我们 -->
            {{ $t("langs.headName2") }}
          </div>
          <div
            class="headerText cursor"
            :class="{ active: $route.path.indexOf('whitebook') > 0 }"
            @click="handleHeader3()"
          >
            <!-- 白皮书 -->
            {{ $t("langs.headName3") }}
          </div>
          <div
            class="headerText cursor"
            :class="{ active: $route.path.indexOf('contactus') > 0 }"
            @click="handleHeader4()"
          >
            <!-- 联系我们 -->
            {{ $t("langs.headName4") }}
          </div>
        </div>
        <div class="language cursor">
          <div class="switchLanguage">
            <div @click="switchLanguage" v-if="$i18n.locale === 'en'">
              EN<img src="../assets/img/down.png" alt="" />
            </div>
            <div @click="switchLanguages" v-if="$i18n.locale === 'zh'">
              中文<img src="../assets/img/down.png" alt="" />
            </div>
            <div class="languages" v-if="flag == true">
              <div @click="handleEnglish">EN</div>
              <div @click="handleChinese">中文</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      flag: false
    };
  },
  created() {},
  mounted() {},
  methods: {
    handleEnglish() {
      this.$i18n.locale = "en";
      this.flag = false;
    },
    handleChinese() {
      this.$i18n.locale = "zh";
      this.flag = false;
    },
    switchLanguage() {
      this.flag = !this.flag;
    },
    switchLanguages() {
      this.flag = !this.flag;
    },
    handleHeader1() {
      this.$router.push("./homepage");
    },
    handleHeader2() {
      this.$router.push("./aboutus");
    },
    handleHeader3() {
      this.$router.push("./whitebook");
    },
    handleHeader4() {
      this.$router.push("./contactus");
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  height: 137px;
  background-color: #191919;
  .content {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    margin: 0 auto;
    background-color: #191919;
    .header {
      width: 100%;
      height: 137px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      color: #ffffff;
      font-size: 24px;
      padding: 0 100px;
      .left {
        width: 40%;
        // padding-left: 150px;
        img {
          width: 257px;
          height: 103px;
        }
      }
      .right {
        display: flex;
        align-items: center;
        justify-content: center;
        .headerText {
          width: 150px;
          height: 137px;
          line-height: 137px;
          margin-right: 10px;
          // border-bottom: 5px solid #ffa900;
          text-align: center;
        }
        .active {
          width: 150px;
          height: 137px;
          line-height: 137px;
          margin-right: 10px;
          border-bottom: 5px solid #ffa900;
          text-align: center;
        }
      }
      .language {
        margin-left: 70px;
        img {
          width: 20px;
          height: 20px;
        }
      }
      .switchLanguage {
        position: relative;
        .languages {
          position: absolute;
          top: 60px;
          left: -5px;
          font-size: 20px;
          line-height: 35px;
          background-color: rgb(161, 161, 161);
          width: 80px;
          text-align: center;
          border-radius: 5px;
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .container {
    width: 100%;
    height: 60px;
    background-color: #191919;
    .content {
      width: 100%;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1000;
      margin: 0 auto;
      background-color: #191919;
      .header {
        width: 100%;
        height: 60px;
        display: flex;
        align-items: center;
        color: #ffffff;
        font-size: 12px;
        padding: 0 15px;
        .left {
          width: 25%;
          padding-left: 0px;
          img {
            width: 80px;
            height: 30px;
          }
        }
        .right {
          display: flex;
          align-items: center;
          justify-content: center;
          .headerText {
            width: 50px;
            height: 65px;
            line-height: 65px;
            margin-right: 0px;
            text-align: center;
          }
          .active {
            width: 50px;
            height: 65px;
            line-height: 65px;
            margin-right: 0px;
            border-bottom: 3px solid #ffa900;
            text-align: center;
          }
        }
        .language {
          margin-left: 10px;
          img {
            width: 10px;
            height: 10px;
          }
        }
        .switchLanguage {
          position: relative;
          .languages {
            position: absolute;
            top: 30px;
            left: -5px;
            font-size: 10px;
            line-height: 18px;
            background-color: rgb(161, 161, 161);
            width: 40px;
            height: 50px;
            line-height: 20px;
            padding-top: 6px;
            text-align: center;
            border-radius: 3px;
          }
        }
      }
    }
  }
}
</style>
