<template>
  <div class="container">
    <headerMain></headerMain>
    <!-- <div class="random"></div> -->
    <div class="aboutus">
      <div class="main1">
        <div class="left">
          <div>{{ $t("langs.conMain1Name1") }}</div>
          <div>400-324-3432</div>
          <div>{{ $t("langs.conMain1Name2") }}：8:30-17:00</div>
          <div>{{ $t("langs.conMain1Name3") }}：9:00-11:00</div>
          <div>{{ $t("langs.conMain1Name4") }}</div>
        </div>
        <div class="right">
          <div>{{ $t("langs.conMain1Name5") }}</div>
          <div>Contact us</div>
          <div class="cursor">
            <img src="../assets/contact/main1.png" alt="" />
            <!-- 查看地图 -->
            {{ $t("langs.conMain1Name6") }}
          </div>
        </div>
      </div>
      <div class="main2">
        <div class="content">
          <div>
            <!-- 客服电话 -->
            {{ $t("langs.conMain2Name1") }}
          </div>
          <div>{{ $t("langs.conMain2Name4") }}：400-213-1532</div>
          <div>{{ $t("langs.conMain2Name4") }}：400-213-1532</div>
        </div>
        <div class="content">
          <div>
            <!-- 商务合作 -->
            {{ $t("langs.conMain2Name2") }}
          </div>
          <div>{{ $t("langs.conMain2Name4") }}：400-213-1532</div>
          <div>{{ $t("langs.conMain2Name4") }}：400-213-1532</div>
        </div>
        <div class="content">
          <div>
            <!-- 加入我们 -->
            {{ $t("langs.conMain2Name3") }}
          </div>
          <div>{{ $t("langs.conMain2Name4") }}：400-213-1532</div>
          <div>{{ $t("langs.conMain2Name4") }}：400-213-1532</div>
        </div>
      </div>
      <footerMain></footerMain>
    </div>
  </div>
</template>

<script>
import headerMain from "../components/Header.vue";
import footerMain from "../components/Footer.vue";
export default {
  components: {
    headerMain,
    footerMain
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {}
};
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  .random {
    height: 137px;
  }
  .aboutus {
    width: 100%;
    .main1 {
      display: flex;
      justify-content: space-between;
      color: #fff;
      padding: 150px 290px;
      background-color: #000;
      .left {
        div:nth-child(1) {
          font-size: 30px;
          font-weight: bold;
        }
        div:nth-child(2) {
          font-size: 41px;
          margin: 12px 0 54px 0;
        }
        div:nth-child(3) {
          font-size: 20px;
          // font-weight: bold;
          margin-bottom: 22px;
        }
        div:nth-child(4) {
          font-size: 20px;
          // font-weight: bold;
          margin-bottom: 176px;
        }
        div:nth-child(5) {
          font-size: 20px;
          // font-weight: bold;
        }
      }
      .right {
        div:nth-child(1) {
          font-size: 50px;
          font-weight: bold;
          margin-top: 50px;
          text-align: center;
        }
        div:nth-child(2) {
          font-size: 30px;
          margin: 16px 0 102px 0;
          text-align: center;
        }
        div:nth-child(3) {
          width: 290px;
          height: 85px;
          line-height: 85px;
          background: #232323;
          border-radius: 43px;
          font-size: 30px;
          font-weight: 800;
          text-align: center;
          img {
            width: 28px;
            height: 40px;
            margin-top: -6px;
          }
        }
      }
    }
    .main2 {
      padding: 150px 260px;
      display: flex;
      justify-content: space-between;
      background-color: #171717;
      .content {
        width: 325px;
        height: 286px;
        color: #fff;
        background: #000;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        div:nth-child(1) {
          font-size: 30px;
          font-weight: bold;
        }
        div:nth-child(2) {
          font-size: 20px;
          // font-weight: 500;
          margin: 34px 0 18px 0;
        }
        div:nth-child(3) {
          font-size: 20px;
          // font-weight: 500;
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .container {
    width: 100%;
    .random {
      height: 137px;
    }
    .aboutus {
      width: 100%;
      .main1 {
        display: flex;
        justify-content: space-between;
        color: #fff;
        padding: 30px 20px;
        background-color: #000;
        .left {
          div:nth-child(1) {
            font-size: 15px;
            font-weight: bold;
          }
          div:nth-child(2) {
            font-size: 20px;
            margin: 6px 0 27px 0;
          }
          div:nth-child(3) {
            font-size: 10px;
            font-weight: bold;
            margin-bottom: 11px;
          }
          div:nth-child(4) {
            font-size: 10px;
            font-weight: bold;
            margin-bottom: 88px;
          }
          div:nth-child(5) {
            font-size: 10px;
            font-weight: bold;
          }
        }
        .right {
          div:nth-child(1) {
            font-size: 21px;
            font-weight: bold;
            margin-top: 25px;
            text-align: center;
          }
          div:nth-child(2) {
            font-size: 15px;
            margin: 8px 0 51px 0;
            text-align: center;
          }
          div:nth-child(3) {
            width: 100px;
            height: 38px;
            line-height: 38px;
            background: #232323;
            border-radius: 21px;
            font-size: 15px;
            font-weight: 800;
            text-align: center;
            img {
              width: 14px;
              height: 20px;
              margin-top: -3px;
            }
          }
        }
      }
      .main2 {
        padding: 30px 10px 10px 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        background-color: #171717;
        .content {
          width: 200px;
          height: 140px;
          color: #fff;
          background: #000;
          margin-bottom: 20px;
          padding: 15px 0;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;

          div:nth-child(1) {
            font-size: 15px;
            font-weight: bold;
          }
          div:nth-child(2) {
            font-size: 10px;
            // font-weight: 500;
            margin: 17px 0 9px 0;
          }
          div:nth-child(3) {
            font-size: 10px;
            // font-weight: 500;
          }
        }
      }
    }
  }
}
</style>
