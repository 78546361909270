<template>
  <div class="footerContent">
    <div class="content">
      <div class="contents">
        <div class="imgFooter box">
          <img src="../assets/img/footer1.png" alt="" />
        </div>
        <div class="box">
          <div class="title1">HOE团队</div>
          <div class="title2">HOE Group</div>
        </div>
        <div class="box">
          <div class="title1">Support</div>
          <div class="title2">Huobi</div>
          <div class="title2">AGID</div>
          <div class="title2">EREGFD</div>
          <div class="title2">EWTRF</div>
          <div class="title2">Help</div>
        </div>
        <div class="box">
          <div class="title1">Team</div>
          <div class="title2">About</div>
          <div class="title2">Join us</div>
        </div>
        <div class="box">
          <div class="title1">Follow us</div>
          <div class="title3">
            <img src="../assets/img/footer2.png" alt="" class="cursor" />
          </div>
          <div class="title3">
            <img src="../assets/img/footer3.png" alt="" class="cursor" />
          </div>
          <div class="title3">
            <img src="../assets/img/footer4.png" alt="" class="cursor" />
          </div>
        </div>
        <div class="box">
          <div class="title1">Developers</div>
          <div class="title3">
            <img src="../assets/img/footer5.png" alt="" class="cursor" />
          </div>
          <div class="title3">
            <img src="../assets/img/footer6.png" alt="" class="cursor" />
          </div>
          <div class="title3">
            <img src="../assets/img/footer7.png" alt="" class="cursor" />
          </div>
          <div class="title3">
            <img src="../assets/img/footer8.png" alt="" class="cursor" />
          </div>
        </div>
      </div>
      <div class="footerMsg">Coyright © 2021 HOE</div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="less">
.footerContent {
  width: 100%;
  background: #000;
  .content {
    width: 1920px;
    margin: 0 auto;
    padding: 112px 150px 41px 150px;
    color: #fff;
    .contents {
      display: flex;
      .imgFooter {
        img {
          width: 72px;
          height: 72px;
        }
      }
      .box {
        width: 252px;
        margin-right: 26px;
        .title3 {
          img {
            width: 27px;
            height: 27px;
          }
        }
      }
      .title1 {
        font-size: 16px;
        font-weight: 800;
        line-height: 40px;
      }
      .title2 {
        font-size: 16px;
        // font-weight: 500;
        margin: 20px 0;
      }
      .title3 {
        margin: 20px 0;
      }
    }
    .footerMsg {
      font-size: 16px;
      text-align: center;
      margin-top: 200px;
    }
  }
}
@media screen and (max-width: 767px) {
  .footerContent {
    width: 100%;
    background: #000;
    .content {
      width: 100%;
      margin: 0 auto;
      padding: 25px 15px 25px 15px;
      color: #fff;
      .contents {
        display: flex;
        .imgFooter {
          img {
            width: 36px;
            height: 36px;
          }
        }
        .box {
          width: 126x;
          margin-right: 13px;
          .title3 {
            img {
              width: 14px;
              height: 14px;
            }
          }
        }
        .title1 {
          height: 40px;
          font-size: 8px;
          font-weight: 800;
          line-height: 20px;
        }
        .title2 {
          font-size: 8px;
          // font-weight: 500;
          margin: 10px 0;
        }
        .title3 {
          margin: 10px 0;
        }
      }
      .footerMsg {
        font-size: 8px;
        text-align: center;
        margin-top: 80px;
      }
    }
  }
}
</style>
